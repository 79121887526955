import { FuseNavigationItem } from '@fuse/components/navigation';
import { InstructorType } from '../../../../api-client';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: "Dashboards",
    type: 'group',
    children: [
      {
        id: 'dashboards-dash-home',
        title: 'Início',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/start',
      }
    ]
  },
  {
    id: 'classes',
    title: "Aulas",
    type: 'group',
    children: [
      {
        id: 'experimetal-class-list',
        title: 'Aulas',
        type: 'basic',
        icon: 'heroicons_outline:computer-desktop',
        link: '/experimental-classes',
        instructorType: InstructorType.Experimental
      },
      {
        id: 'classes-nav',
        title: 'Aulas Experimentais',
        type: 'basic',
        icon: 'heroicons_outline:computer-desktop',
        link: '/classes/list',
        instructorType: InstructorType.Effective
      },
    ]
  },

  {
    id: 'students-nav',
    title: "Alunos",
    type: 'group',
    children: [
      {
        id: 'students-nav',
        title: 'Alunos',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/students/list',
      },
      {
        id: 'distribution-request',
        title: 'Solicitações',
        type: 'basic',
        icon: 'heroicons_outline:cloud-arrow-down',
        link: '/students/requests',
      },
    ]
  },
  {
    id: 'instructors',
    title: "Professores",
    type: 'group',
    children: [
      {
        id: 'hours-list',
        title: 'Horários',
        type: 'basic',
        icon: 'heroicons_outline:calendar-days',
        link: '/instructors/calendar',

      },
      {
        id: 'calendar-list',
        title: 'Calendário',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/instructors/hours',

      },
      {
        id: 'documents-list',
        title: 'Materiais',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document',
        link: '/documents',
      },
    ]
  },
  {
    id: 'chat-with-students',
    title: "Chat com estudantes",
    type: 'basic',
    icon: 'alianca:chats',
    link: '/chat',
    instructorType: InstructorType.Effective
  },
  {
    id: 'helpdesk-menu',
    title: 'Suporte',
    type: 'group',
    children: [
      {

        id: 'helpdesk',
        title: 'Ajuda',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: 'http://help.aliancaamerica.com.br',
        externalLink: true,
        target: '_blank',
      }
    ]
  }
];
