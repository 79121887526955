import { Component, Inject, InjectionToken, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';

export const STUDENT_WEB_BASE_URL  = new InjectionToken<string>('STUDENT_WEB_BASE_URL');

@Component({
  selector: 'auth-sign-in',
  templateUrl: './sign-in.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class AuthSignInComponent implements OnInit {
  @ViewChild('signInNgForm') signInNgForm: NgForm;

  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: ''
  };
  signInForm: UntypedFormGroup;
  showAlert: boolean = false;

  private _baseUrl: string;

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    @Optional() @Inject(STUDENT_WEB_BASE_URL) baseUrl?: string
  ) {
    this._baseUrl = baseUrl;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Clear cache
    localStorage.clear();
        sessionStorage.clear();

        document.cookie.split(";").forEach(function (cookie) {
            document.cookie = cookie.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
        });

        if (caches) {
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName);
                });
            });
        }
    // Create the form
    this.signInForm = this._formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   */
  signIn(): void {
    // Return if the form is invalid
    if (this.signInForm.invalid) {
      return;
    }

    // Disable the form
    this.signInForm.disable();

    // Hide the alert
    this.showAlert = false;

    // Sign in
    this._authService.signIn(this.signInForm.value)
      .subscribe(
        (response) => {

          const token = AuthUtils._decodeToken(response.token);
          const isComplete: boolean = token.isComplete?.toLowerCase() == "true";

          if(isComplete){
            if(token.type == 'Student') {
              localStorage.clear();
              window.location.href = this._baseUrl + '/login?token=' + response.token;
            } else {
              if(response.user.termUsers.length > 0 ) {
                this._router.navigateByUrl('/terms');
              } else {
                const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
                this._router.navigateByUrl(redirectURL);
              }             
            }
          } else {
            this._router.navigateByUrl("/first-access");
          }
        },
        (error) => {
          this.signInForm.enable();

          this.signInNgForm.resetForm();

          this.alert = {
              type: 'error',
              message: error.detail || 'Email ou senha inválidos!'
          };

          this.showAlert = true;
        }
      );
  }

  onFirstAccessClick(): void {
    this._router.navigate(['/forgot-password'], { queryParams: { 'first-access': true } });
  }
}
