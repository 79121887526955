import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { UsersClient } from '../../api-client';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {
    /**
     * Constructor
     */
    constructor(
        private _messagesService: MessagesService,
        private _navigationService: NavigationService,
        private _quickChatService: QuickChatService,
        private _shortcutsService: ShortcutsService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._navigationService.get(),
            this._messagesService.getAll(),
            this._quickChatService.getChats(),
            this._shortcutsService.getAll()
        ]);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TermsResolver implements Resolve<any> {

    constructor(
        private usersClient: UsersClient,
        private route: Router,
        private loaderService: NgxUiLoaderService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        this.loaderService.start();

        this.usersClient.getCurrentUser().subscribe(user => {
            if (user.termUsers.length > 0) {
                this.route.navigate(['/terms-redirect']).then(() => { });
            }
            this.loaderService.stop()
        });
    }
}